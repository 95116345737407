
import {
  IonList,
  IonListHeader,
  IonIcon,
  IonChip,
  IonLabel,
  IonRange,
  IonButton,
} from "@ionic/vue";
import { ref, defineComponent, computed } from "vue";
import {
  myCriteria,
  validationOn,
  openEducationModal,
  openWorkModal,
  openSmokingModal,
  openReligionModal,
  openLanguagesModal,
  openLocationsModal,
} from "@/components/criteria";
import {
  addCircleOutline,
  schoolOutline,
  logoNoSmoking,
  languageOutline,
  triangleOutline,
  briefcaseOutline,
  locationOutline,
  arrowDownOutline,
  arrowUpOutline,
} from "ionicons/icons";

import jsonData from "@/assets/criteriaData.json";

export default defineComponent({
  name: "CriteriaEditForm",
  components: {
    IonList,
    IonListHeader,
    IonIcon,
    IonChip,
    IonButton,
    IonLabel,
    IonRange,
  },
  setup() {
    const additionalCriteriaFlag = ref<boolean>(false);
    const toggleAdditionalCriteria = async () => {
      additionalCriteriaFlag.value = !additionalCriteriaFlag.value;
    };

    const listOfJobs = ref(jsonData.jobs);
    const listOfReligions = ref(jsonData.religions);
    const listOfLanguages = ref(jsonData.languages);
    const listOfEducation = ref(jsonData.education);
    const listOfSmoking = ref(jsonData.smoking);
    const listOfLocations = ref(jsonData.locations);

    const jobsToShow = computed(() => {
      return listOfJobs.value.filter((item) => {
        if (myCriteria.value.works) {
          const works = Object.keys(myCriteria.value.works);
          return works.includes(item.code);
        } else {
          return false;
        }
      });
    });
    const religionsToShow = computed(() => {
      return listOfReligions.value.filter((item) => {
        if (myCriteria.value.religions) {
          const religions = Object.keys(myCriteria.value.religions);
          return religions.includes(item.code);
        } else {
          return false;
        }
        // return myCriteria.religion?.has(item.code);
      });
    });
    const languagesToShow = computed(() => {
      return listOfLanguages.value.filter((item) => {
        if (myCriteria.value.languages) {
          const languages = Object.keys(myCriteria.value.languages);
          return languages.includes(item.code);
        } else {
          return false;
        }
        // return myCriteria.languages?.has(item.code);
      });
    });
    const locationsToShow = computed(() => {
      return listOfLocations.value.filter((item) => {
        if (myCriteria.value.locations) {
          const locations = Object.keys(myCriteria.value.locations);
          return locations.includes(item.code);
        } else {
          return false;
        }
        // return myCriteria.locations?.has(item.code);
      });
    });
    const educationToShow = computed(() => {
      return listOfEducation.value.filter((item) => {
        return myCriteria.value.education == item.code;
      });
    });
    const smokingToShow = computed(() => {
      return listOfSmoking.value.filter((item) => {
        return myCriteria.value.smoking == item.code;
      });
    });

    const toggleRegion = (regionCode: string) => {
      if (myCriteria.value.locations) {
        if (myCriteria.value.locations[regionCode]) {
          delete myCriteria.value.locations[regionCode];
        } else {
          myCriteria.value.locations[regionCode] = true;
        }
      } else {
        myCriteria.value.locations = {};
        myCriteria.value.locations[regionCode] = true;
      }
      // if (myCriteria.locations?.has(regionCode)) {
      //   myCriteria.locations.delete(regionCode);
      // } else {
      //   myCriteria.locations?.add(regionCode);
      // }
    };

    return {
      additionalCriteriaFlag,
      toggleAdditionalCriteria,
      toggleRegion,
      validationOn,
      // myCriteria,
      myCriteria,

      jobsToShow,
      religionsToShow,
      languagesToShow,
      educationToShow,
      smokingToShow,
      locationsToShow,
      // ICONS
      addCircleOutline,
      schoolOutline,
      logoNoSmoking,
      languageOutline,
      locationOutline,
      triangleOutline,
      briefcaseOutline,
      arrowDownOutline,
      arrowUpOutline,

      // MODALS
      openEducationModal,
      openWorkModal,
      openSmokingModal,
      openReligionModal,
      openLanguagesModal,
      openLocationsModal,
    };
  },
});
